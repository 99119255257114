<template>
  <div class="_bg-default _100vh">
    <div class="py-5" v-if="!loading">
      <div class="my-3">
        <h3>All Survey</h3>
        <v-divider></v-divider>
      </div>
      <v-row>
        <v-col cols="12" md="4" sm="6" v-if="role == 3">
          <v-card
            height="210"
            class="radius-card"
            elevation="0"
            to="/psikolog/create-survey"
          >
            <div
              class="d-flex justify-center align-center"
              style="height: 100%"
            >
              <v-btn fab depressed color="blue lighten-4"
                ><v-icon size="70" color="blue">mdi-plus</v-icon></v-btn
              >
            </div>
          </v-card>
        </v-col>
        <template v-if="dataSet.length">
          <v-col cols="12" md="4" sm="6" v-for="item in dataSet" :key="item.id">
            <v-card height="210" class="radius-card" elevation="0">
              <v-toolbar dense flat class="pt-2 px-0">
                <div class="d-flex align-center" v-if="item.user">
                  <v-avatar class="mr-2">
                    <img
                      :src="
                        item.user.photo_profile
                          ? `${env}/upload/photo_profile/${item.user.id}/${item.user.photo_profile}`
                          : `${env}/images/logo.png`
                      "
                      class="img-fit"
                      alt=""
                    />
                  </v-avatar>
                  <h4 v-snip="1">{{ item.user.nama_lengkap }}</h4>
                </div>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-title
                class="d-flex justify-center pa-2"
                style="height: 100px"
              >
                <h3 class="text-center" v-snip="2">{{ item.judul }}</h3>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-actions>
                <v-chip
                  class="mx-2 white--text"
                  :color="
                    item.kategori == 'Psikologi Klinis'
                      ? 'a__'
                      : item.kategori == 'Psikologi Pendidikan'
                      ? 'b__'
                      : 'c__'
                  "
                >
                  {{ item.kategori }}
                </v-chip>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue"
                  fab
                  depressed
                  dark
                  small
                  content="View Detail"
                  v-tippy="{
                    arrow: true,
                    arrowType: 'round',
                    animation: 'fade',
                    theme: 'light',
                    maxWidth: 100,
                  }"
                  :to="`/survey/${item.id}/view`"
                >
                  <v-icon small>mdi-eye</v-icon>
                </v-btn>
              </v-card-actions>
              <!-- {{ survey }} -->
            </v-card>
          </v-col>
        </template>
      </v-row>
      <div v-if="!dataSet.length" class="d-flex align-center flex-column mt-3">
        <img src="../../assets/img/404.svg" height="100px" alt="404" />
        <h3>Data Not Found!</h3>
      </div>
    </div>
    <div v-else>
      Loading...
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "surveys",

  data() {
    return {
      loading: true,
      currentPage: 1,
      limit: 10,
      valueDeterminate: 50,
      dataSet: [],
    };
  },
  computed: {
    ...mapState({
      survey: (state) => state.survey.survey,
      last: (state) => state.admin.last_page,
      env: (state) => state.API_URL,
      role: (state) => state.role,
    }),
  },
  mounted() {
    this.fetchItem();
  },
  methods: {
    fetchItem() {
      this.loading = true;
      this.$store
        .dispatch("survey/listSurvey")
        .then((res) => {
          let raw = res.data;
          raw.forEach((el) => {
            el["user"] = null;
            el.relationships.forEach((sub) => {
              if (sub.user.data) {
                el["user"] = sub.user.data;
              }
            });
          });
          console.log("masuk sini ga");
          this.dataSet = raw;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
